import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

import {
    Flex,
    Button,
    SimpleGrid,
    Heading,
    Spacer,
    Divider,
    Input,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Calendar from "./components/Calendar";
import WinnersTable from "./components/WinnersTable";

import messages from "./messages/toast";

function Raffle() {
    const router = useHistory();
    const toast = useToast();
    const [loading, setLoading] = useState({
        prevWinnersLoading: false,
        confirmWinnersLoading: false,
    });
    const [clear, setClear] = useState(false);
    const [raffleData, setRaffleData] = useState({
        raffleWeekStart: "",
        raffleWeekEnd: "",
        raffleWeek: "",
        raffleType: "",
    });
    const [raffleName, setRaffleName] = useState("Выберите тип розыгрыша");
    const [prevWinners, setPrevWinners] = useState({});

    function openUserCard(id) {
        router.push(`/dashboard/user-card/${id}`);
    }
    function openQrCard(id) {
        router.push(`/dashboard/check-card/${id}`);
    }

    function setRaffleType(e) {
        const target = e.target.textContent;

        setRaffleName(target);
        setRaffleData({ ...raffleData, raffleType: e.target.name });
    }

    async function getPrevWinners() {
        try {
            if (!raffleData.raffleWeek) throw new Error("Введите название розыгрыша");
            if (!raffleData.raffleWeekStart || !raffleData.raffleWeekEnd)
                throw new Error("Неверные настройки периода или нет подходящих чеков");

            setLoading({ ...loading, prevWinnersLoading: true });

            const { data } = await axios.post(
                `${process.env.REACT_APP_API_URL}/raffle/prev-raffle-winners`,
                raffleData
            );

            setLoading({ ...loading, prevWinnersLoading: false });
            setPrevWinners(data.data);

            localStorage.setItem("prevWinners", JSON.stringify(data.data));
            localStorage.setItem("raffleData", JSON.stringify(raffleData));
        } catch (error) {
            messages("Победителей за указанный период не найдено", "error", toast);
            setLoading({ ...loading, prevWinnersLoading: false });
        }
    }

    async function confirmWinners() {
        try {
            const raffleData = JSON.parse(localStorage.getItem("raffleData"));
            const raffleWinners = JSON.parse(localStorage.getItem("prevWinners"));

            if (!raffleWinners) throw new Error("Победители не найдены");
            if (!raffleData.raffleWeek) throw new Error("Не указан период розыгрыша");

            setLoading({ ...loading, confirmWinnersLoading: true });

            await axios.post(`${process.env.REACT_APP_API_URL}/raffle/fill-raffle-db`, {
                raffleWinners,
                raffleWeek: raffleData.raffleWeek,
                raffleType: raffleData.raffleType,
            });

            setLoading({ ...loading, confirmWinnersLoading: false });

            messages("Розыгрыш проведен успешно", "success", toast);
            clearRaffle();
        } catch (error) {
            messages(error.message, "error", toast);
            setLoading({ ...loading, confirmWinnersLoading: false });
        }
    }

    function clearRaffle() {
        localStorage.removeItem("prevWinners");
        localStorage.removeItem("raffleData");

        setPrevWinners({});
        setRaffleData({ ...raffleData, raffleWeek: "", raffleType: "" });
        setRaffleName("Выберите тип розыгрыша");
    }

    useEffect(() => {
        const getWinnersFromLS = localStorage.getItem("prevWinners");

        if (getWinnersFromLS === null) {
            setPrevWinners({});
            return;
        }
        const response = JSON.parse(getWinnersFromLS);
        setPrevWinners(response);
    }, []);

    return (
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
            <SimpleGrid columns={{ sm: "1", md: "2" }} gap='20px' mb='24px'>
                <Card w='100%'>
                    <CardHeader display='flex' justifyContent='center' mb='10px'>
                        <Heading size='md'>Период розыгрыша</Heading>
                    </CardHeader>

                    <Divider />
                    <Spacer />

                    <CardBody display='flex' justifyContent='center' h='100%' alignItems='center'>
                        <Flex direction='column' gap='10px'>
                            <Calendar setRaffleData={setRaffleData} raffleData={raffleData} />
                        </Flex>
                    </CardBody>
                </Card>

                <Card w='100%'>
                    <CardHeader display='flex' justifyContent='center' mb='10px'>
                        <Heading size='md'>Тип розыгрыша</Heading>
                    </CardHeader>

                    <Divider />
                    <Spacer />

                    <CardBody>
                        <Flex justify='center' direction='column' w='100%' mt='20px' gap='15px'>
                            <Menu>
                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                    {raffleName}
                                </MenuButton>
                                <MenuList onClick={setRaffleType}>
                                    <MenuItem name='weekly'>Еженедельный</MenuItem>
                                </MenuList>
                            </Menu>

                            <Input
                                isDisabled={
                                    !raffleData.raffleWeekStart ||
                                    !raffleData.raffleWeekEnd ||
                                    !raffleData.raffleType
                                }
                                placeholder='Введите название розыгрыша'
                                onChange={(e) =>
                                    setRaffleData({
                                        ...raffleData,
                                        raffleWeek: e.target.value,
                                    })
                                }
                                value={raffleData.raffleWeek}
                            />
                        </Flex>
                    </CardBody>
                </Card>
            </SimpleGrid>

            <Button
                colorScheme='linkedin'
                mb='20px'
                isLoading={loading.prevWinnersLoading}
                onClick={getPrevWinners}
            >
                Провести розыгрыш
            </Button>

            <Card my='25px'>
                <CardHeader display='flex' justifyContent='center'>
                    <Heading fontSize={["sm", "md", "lg", "xl"]}>
                        Предварительные победители розыгрыша
                    </Heading>
                </CardHeader>

                <CardBody>
                    <WinnersTable
                        data={prevWinners}
                        openUserCard={openUserCard}
                        openQrCard={openQrCard}
                    />
                </CardBody>
            </Card>

            <Flex gap='20px' justify={{ sm: "center", md: "flex-start" }}>
                <Button
                    colorScheme='green'
                    onClick={confirmWinners}
                    isLoading={loading.confirmWinnersLoading}
                >
                    Подтвердить
                </Button>

                <Button onClick={clearRaffle} colorScheme='red'>
                    Отменить
                </Button>
            </Flex>
        </Flex>
    );
}

export default Raffle;

import { useState, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Flex, Button, Text } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CheckTable from "./components/CheckTable";
import MenuComponent from "./components/MenuComponent";
import PagePagination from "./components/PagePagination";
import StatBlock from "./components/StatBlock";
import InputComponent from "./components/InputComponent";
import CheckBoxComponent from "./components/CheckBoxComponent";
import DateRangePicker from "./components/DatePicker/DateRangePicker";
import SkeletonComponent from "./components/SkeletonComponent";

import useLocalStorage from "hooks/useLocalStorage";
import useSearchValue from "hooks/useSearchValue";
import useRequestArray from "hooks/useRequestArray";
import useRequest from "hooks/useRequest";
import exportToExel from "helper/exportToExel";

function WaitChecksData() {
    const router = useHistory();
    const qrLimitLs = useLocalStorage("qrLimitWait");
    const qrPage = useLocalStorage("qrPageWait");
    const [checkData, setCheckData] = useState({
        dateStart: localStorage.getItem("periodStart") || "",
        dateEnd: localStorage.getItem("periodEnd") || "",
    });
    const [status, setStatus] = useState(
        JSON.parse(localStorage.getItem("checkBoxFilterWait")) || {
            wait: true,
            onHold: true,
        }
    );
    const [searchValue, setSearchValue] = useState(localStorage.getItem("searchCheckValue") || "");
    const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
    const [order, setOrder] = useState(localStorage.getItem("qrOrdersWait") || "DESC");
    const [limitOnPage, setLimitOnPage] = useState(qrLimitLs.getItem() || "5");
    const [page, setPage] = useState(qrPage.getItem() || 1);

    const allChecks = useRequestArray(
        `qr/get-all-wait-qrcodes?order=${order}&limit=${
            debouncedSearchValue.length ? "ALL" : limitOnPage
        }&page=${page}&period=${JSON.stringify(checkData)}&status=${JSON.stringify(status)}`,
        "post"
    );
    const { postData, postRequestData } = useRequest("qr/verify");
    const filteredChecks = useSearchValue(allChecks.responseData.data, debouncedSearchValue, page);

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(...args), delay);
        };
    };

    const debouncedSearch = useCallback(
        debounce((value) => {
            setDebouncedSearchValue(value);
            localStorage.setItem("searchCheckValue", value);
        }, 800),
        []
    );

    const handleSearchChange = (value) => {
        setSearchValue(value);
        debouncedSearch(value);
    };

    function openCard(id) {
        router.push(`/dashboard/check-card/${id}`);
    }

    function handleChangeLimitOnPage(e) {
        const target = e.target.name;
        setLimitOnPage(target);
        setPage(1);
        qrLimitLs.setItem(target);
        qrPage.setItem(1);
    }

    useMemo(() => {
        localStorage.setItem("checkBoxFilterWait", JSON.stringify(status));
    }, [status]);

    async function doCheckVerify() {
        await postData(
            {},
            {
                successTitle: "Чеки успешно проверены",
                errorTitle: "Нет подходящих чеков для проверки",
            }
        );
    }

    return (
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
            <StatBlock />

            <InputComponent searchValue={searchValue} setSearchValue={handleSearchChange} />

            <Flex mb='20px'>
                <Card>
                    <DateRangePicker
                        setCheckData={setCheckData}
                        checkData={checkData}
                        setPage={setPage}
                    />
                </Card>
            </Flex>

            <Flex
                direction={{ base: "column", md: "row" }}
                gap='20px'
                justify='space-between'
                align='center'
                w='100%'
                mb='24px'
            >
                <MenuComponent
                    limitOnPage={limitOnPage}
                    handleChangeLimitOnPage={handleChangeLimitOnPage}
                />

                <CheckBoxComponent
                    status={status}
                    setStatus={setStatus}
                    setPage={setPage}
                    activeFields={{ onHold: true, wait: true, verified: false, notVerified: false }}
                />

                <Button
                    w={{ base: "100%", md: "max-content" }}
                    colorScheme='linkedin'
                    isLoading={postRequestData.isLoading}
                    onClick={doCheckVerify}
                >
                    Автомодерация
                </Button>

                <Button
                    w={{ base: "100%", md: "max-content" }}
                    onClick={() => exportToExel("Checks", filteredChecks)}
                >
                    Выгрузить чеки
                </Button>
            </Flex>

            <Card px='0px' py='0px'>
                <CardBody justifyContent='center'>
                    {allChecks.responseData.data && !allChecks.isError && !allChecks.isLoading && (
                        <CheckTable
                            data={filteredChecks}
                            open={openCard}
                            setOrder={setOrder}
                            order={order}
                            orderType={"qrOrdersWait"}
                        />
                    )}
                    {allChecks.isError && <Text>Нет чеков по заданным параметрам</Text>}
                </CardBody>
                {allChecks.isLoading && <SkeletonComponent height={100} />}
            </Card>

            {limitOnPage !== "ALL" && debouncedSearchValue.length === 0 && (
                <PagePagination
                    page={page}
                    setPage={setPage}
                    totalPages={allChecks.responseData.totalPages}
                    pageInLs='qrPageWait'
                    isError={allChecks.isError}
                />
            )}
        </Flex>
    );
}

export default WaitChecksData;
